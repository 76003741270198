import { CommonAPI, RESOURCE } from './CommonAPI';
import {
  requestApi,
  postRequestWithFormData,
  paramsToFormData,
  putRequestWithFormData,
  deleteRequestWithFormData,
  requestApiWithAuthentication,
} from './request';
import {
  ListDataParams,
  ListResponseResult,
  SingleResponseResult,
} from '../models/api/ResponseData';
import {
  Position,
  PositionContractStatus,
  PositionList,
  RecommendedCandidates,
} from '../models/Position';

export type PositionOrderType = 'best' | 'created_at';

export type ListPositionsResponse = ListResponseResult<PositionList>;
export type SinglePositionResponse = SingleResponseResult<Position>;
export type ListRecommendedCandidatesResponse =
  ListResponseResult<RecommendedCandidates>;

export interface CreatePositionParams {
  companyId: number;
  contractStatus: string;
  category: string;
  severity: string;
  title: string;
  description: string;
  startedAt: string;
  attachedFiles: any[];
}

export interface UpdatePositionParams {
  companyId?: number;
  contractStatus?: string;
  title?: string;
  description?: string;
  startedAt?: string;
  closedAt?: string;
  attachedFiles?: any[];
  removedFileUrls?: string[];
  category?: string;
  severity?: string;
}

export interface UpdateBulkPositionParams {
  contractStatus?: PositionContractStatus;
}

export class PositionAPIClass extends CommonAPI<Position> {
  resource = RESOURCE.POSITION;

  public loadPositions = async ({
    page,
    sort,
    query,
    ...params
  }: ListDataParams): Promise<ListPositionsResponse> => {
    const axiosResponse = await requestApi(this.resource, {
      method: 'get',
      params: {
        size: 10,
        page,
        sort,
        query,
        ...params,
      },
    });

    return axiosResponse.data;
  };

  public loadAllPositions = async ({
    companyId,
  }: {
    companyId?: number;
  }): Promise<ListPositionsResponse> => {
    const INF_SIZE = 1000000;
    const axiosResponse = await requestApi(this.resource, {
      method: 'get',
      params: {
        size: INF_SIZE,
        page: 1,
        company_id: companyId,
      },
    });

    return axiosResponse.data;
  };

  public loadPosition = async (id: number): Promise<SinglePositionResponse> => {
    const axiosResponse = await requestApi(`${this.resource}/${id}`, {
      method: 'get',
    });

    return axiosResponse.data;
  };

  public loadRecommendedCandidates = async (
    positionId: number,
    { page, size, ...params }: ListDataParams,
  ): Promise<ListRecommendedCandidatesResponse> => {
    const axiosResponse = await requestApi(
      `${this.resource}/${positionId}/candidates/recommend`,
      {
        method: 'get',
        params: {
          size,
          page,
          ...params,
        },
      },
    );

    return axiosResponse.data;
  };

  public create = async (
    params: CreatePositionParams,
  ): Promise<SinglePositionResponse> => {
    const axiosResponse = await postRequestWithFormData(
      this.resource,
      paramsToFormData(params),
    );

    return axiosResponse.data;
  };

  public updatePosition = async (
    positionId: number,
    params: UpdatePositionParams,
  ): Promise<SinglePositionResponse> => {
    const axiosResponse = await putRequestWithFormData(
      `${this.resource}/${positionId}`,
      paramsToFormData(params),
    );

    return axiosResponse.data;
  };

  public updatePositionBulk = async (
    positionIdList: number[],
    params: UpdateBulkPositionParams,
  ): Promise<SinglePositionResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}`,
      { method: 'put', data: { positionIdList, ...params } },
    );

    return axiosResponse.data;
  };

  public deletePositionBulk = async (
    positionIdList: number[],
  ): Promise<SinglePositionResponse> => {
    const axiosResponse = await deleteRequestWithFormData(
      this.resource,
      paramsToFormData({ positionIdList }),
    );

    return axiosResponse.data;
  };
}

export const PositionAPI = new PositionAPIClass();
